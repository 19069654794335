import React from "react"
import Layout from "../components/layout"
import lawfrontpage from "../images/lawfrontpage.png"

const AboutPage = () => (
  <Layout>
    <section class="about backpage">
      <div class="container">
        <div class="text-container">
          <h1>My Journey</h1>
          <p>For the past four and a half years, I have worked for the College of Law at the West Virginia Univeristy. One of my first tasks that I took on was to update the existing site. About a thousand pages had to be transferred into a new content management system and redesigned to fit the University's new branding guidelines.</p>

          <a href="law.wvu.edu"><img src={lawfrontpage} alt="WVU College of Law's frontpage."/></a>

          

        </div>
      </div>
    </section>
  </Layout>

)

export default AboutPage